
import { Vue, Component } from "vue-property-decorator";
import user from "@/store/modules/UserModule";
import { UserService } from "@/api/UserServiceApi";

@Component({})
export default class DashboardProfile extends Vue {
  get companyName(): string {
    return user.companyName;
  }

  get businessCode(): string {
    return user.businessCode;
  }

  get contactPerson(): string {
    return user.contactPerson;
  }

  get email(): string {
    return user.email;
  }

  get mobilePhone(): string {
    return user.mobilePhone;
  }

  get emailIsVerified(): boolean {
    return user.hasVerifiedEmail;
  }

  async sendEmailVerification() {
    await UserService.SendVerificationEmail().then(() => {
      this.$swal.fire({
        titleText: this.$t("MODAL.VERIFICATION_MESSAGE.TITLE").toString(),
        confirmButtonText: "Ok",
        icon: "success"
      });
    });
  }
}
